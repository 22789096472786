<template>
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="footer-content col-md-6 col-12">
                Gewerbekamp 20<br/>
                27616 Beverstedt/Lunestedt<br/><br/>
                <font-awesome-icon icon="phone" class="fas fa-phone"/> +49 (0)4748 3115<br/>
            </div>
            <div class="footer-content col-md-6 col-12">
                FREIWILLIGE FEUERWEHR LUNESTEDT<br><br/>
                <!--<router-link to="/about">About us</router-link><br>
                <a id="contact" href="mailto:">Contact</a>-->
            </div>
        </div>
    </div>
</div>

<div class="footer-banner">
    <div class="container">
        <div class="row">
            <div class="footer-link col-lg-2 col-md-3 col-12"><router-link to="/imprint" >Impressum</router-link></div>
            <div class="footer-link col-lg-2 col-md-3 col-12"><router-link to="/privacyPolicy" >Datenschutz</router-link></div>
            <div class="footer-link col-lg-2 col-md-3 col-12"><!--<router-link to="/termsOfUse" >Nutzungsbedingungen</router-link>--></div>
            <div class="footer-link col-lg-6 col-md-3 col-12"></div>
        </div>
    </div>
</div>

</template>

<script>
export default {
name: "foot",
    data() {
        return {
            ENV: process.env
        };
    }
}
</script>

<style scoped>
a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.footer {
    margin-top: 100px;
    width: 100%;
    background: var(--secondary-color);
    color: #FFF;
}

.footer .footer-content {
    margin: 30px 0;
}

.footer .footer-logo {
    width: 350px;
    max-width: 90%;
    margin-top: -8px;
}

.footer a {
    color: #FFF;
}

.footer a:hover {
    opacity: 0.8;
    color: #FFF;
    
}

.footer .footer-sponsor:hover {
    opacity: 1;
}

.footer .social a i {
    margin-right: 10px;
    font-size: 20px;
}

.footer-banner {
    width: 100%;
    color: #C8C8C8;
    background: var(--dark-color);
    display: flex;
    align-items: center;
}

.footer-banner .footer-link {
    padding: 18px 15px;
}

.footer-banner a {
    color: #C8C8C8;
}

.footer-banner a:hover {
    color: #FFF;
}
.text-right {
    text-align: right!important;
}
.footer  a {
    color: #FFF;
}

</style>
