<template>
	<div class="searchbar-wrapper">
		<div class="container">
            
		</div>
	</div>
</template>
<script>


export default {
    name: "Searchbar",
    components: {

    },
};

</script>
<style>

.searchbar-wrapper {
    background-color: #ededed;
    position: relative;
    width: 100%;
    height: var(--searchbar-height);
}
</style>