<template>
  <Navigation/>

  <div id="main" class="main-content">
      <div class="container padding-to-header">
        <!-- component matched by the route will render here -->
        <router-view></router-view>
      </div>
  </div>
  <foot/>
</template>
<script>
import Navigation from '@/components/Navigation.vue'
import foot from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Navigation,
    foot,
  },
  computed: {
  }
}
</script>

<style>
:root {
    --primary-color: #b90000;
    --primary-light-color: #c7edf0;
    --secondary-color: #500f0f;
    --dark-color: #360a0a;
    --grey-color: #8c8c8c;
    --grey-light-color: #cccccc;
    --complementary-color: #eb5e64;
    --border-radius: 0px;
    --inverted-color: #004ab9;

    --nav-height: 98px;
    --nav-height-slim: 58px;
    --nav-padding: 1rem;
    --nav-padding-slim: 0.5rem;
    --searchbar-height: 42px;
}

body {
    margin: 0;
    padding: 0;
    background: #ebebeb;
    overflow-x: hidden;
    font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, Sans-Serif;
}

h1 {
    color: var(--primary-color);
    font-size: 30px;
    font-weight: 600;
}

h2 {
    font-size: 24px;
}

h3 {
    color: var(--primary-color);
    font-weight: 700;
	font-weight: 700; 
    font-weight: 700;
    font-size: 18px;
}

h4 {
    color: var(--primary-color);
    font-size: 12px;
}

h5 {
    color: #AAA;
    font-size: 17px;
    font-size: 17px; 
    font-size: 17px;
}

p {
    font-size: 15px;
	font-size: 15px; 
    font-size: 15px;
    padding: 5px 0px 5px 0px;
}
thead {
  text-transform: uppercase;
}
.home {
    background-color: #F6F6F6;
    padding: 50px;
}

.main-content {
    margin-top: calc( var(--nav-height) + var(--searchbar-height));
    min-height: calc( 100vh - var(--nav-height) - var(--searchbar-height) );
    transition: all 0.5s ease-in-out;
}

 .main-content .container.padding-to-header {
    padding-top: 0px;
}

.nav-container.slim + .main-content .container.padding-to-header {
    padding-top: 0px;
}

/* slim mode for viewers */

.nav-container.slim .navbar {
    min-height: var(--nav-height-slim);
}

.nav-container.slim .navbar .navbar-brand {
    height: calc( var(--nav-height-slim) - (var(--nav-padding-slim) * 2));
    width: calc((var(--nav-height-slim) - (var(--nav-padding-slim) * 2)) / 0.117);
}

.nav-container.slim+.main-content {
    margin-top: calc( var(--nav-height-slim) + var(--searchbar-height));
    min-height: calc( 100vh - var(--nav-height-slim) - var(--searchbar-height));
}
@media(max-width:900px) {
  .bilder {
    width: 95% !important;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 15px;
  }
  h1 {
    font-size: 26px;
  }
}
</style>
