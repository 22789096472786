import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  //{
  //  path: '/about',
  //  name: 'About',
  //  component: () => import('../views/About.vue')
  //},
  {
    path: '/imprint',
    name: 'Impressum',
    component: () => import('../views/Imprint.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'Datenschutzerklärung',
    component: () => import('../views/privacyPolicy.vue')
  },
  {
    path: '/Kinderfeuerwehr',
    name: 'Kinderfeuerwehr',
    component: () => import('../views/KinderFeuer.vue')
  },
  {
    path: '/Jugendfeuerwehr',
    name: 'Jugendfeuerwehr',
    component: () => import('../views/JugendFeuer.vue')
  },
  {
    path: '/Einsatzabteilung',
    name: 'Einsatzabteilung',
    component: () => import('../views/Aktiv.vue')
  },
  {
    path: '/Altersabteilung',
    name: 'Altersabteilung',
    component: () => import('../views/AltersAbteilung.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
})

export default router
